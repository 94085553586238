.textonlycontainer {
  border: 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  padding: 1% 5%;
  border-radius: 10px;
  width: 96%;
  margin: 45px;

  /* display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center; */
}
@media (max-width: 768px) {
  .textonlycontainer {
    margin-left: 10px;
  }
}

.videodualcolumn {
  width: 560px;
  height: 314px;
  /* padding: 0 1% 0 1%; */
}
@media (max-width: 570px) {
  .videodualcolumn {
    width: 360px;
    height: 280px;
    /* padding: 0 1% 0 1%; */
  }
}

.list {
  max-width: 100%;
}
.list li {
  margin: 10px;
  list-style: circle;
}
@media (max-width: 768px) {
  .space {
    margin-left: 25px;
  }
}

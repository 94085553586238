/*START OF THE WHATSAPP ICON (STICKY ICON) WITH WHATSAPP LINK*/

.icon-bar {
  position: fixed;
  top: 50%;
  right: 2%;
  width: 60px;
  height: 60px;
  border-radius: 40px;
  background-color: #25d366;
  box-shadow: 2px 2px 3px 2px rgba(0, 0, 0, 0.3);
  text-shadow: 0 0 3px #000;
}

.green-color {
  color: white;
  padding: 10;
}

.iconcontainer {
  position: fixed;
  right: 10%;
  text-align: center;
  margin-top: 0px;
  background-color: #cccccc;
  float: right;
  padding-left: 75%;
}

@media only screen and (max-width: 600px) {
  .iconcontainer {
    right: 18%;
  }
}

/*END OF THE WHATSAPP ICON (STICKY ICON) WITH WHATSAPP LINK*/

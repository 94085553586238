.content-container.textmodule {
  display: flex;
  flex-direction: column;
}

.textmodule .row {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.textmodule .text-justify {
  text-align: center;
}

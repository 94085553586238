.video-container {
  padding: 0;
  margin: 0;
  position: relative;
  width: 100%;
  height: 75vh;
  object-fit: cover;
}
.hero-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.hero-one-img-content {
  bottom: 20%;
  position: absolute;
}
@media (max-width: 767px) {
  .video-container {
    padding: 0;
    margin: 0;
    position: relative;
    width: 100%;
    height: 40vh;
    object-fit: cover;
  }
  .hero-one-img-content {
    top: 25%;
    position: absolute;
  }
}
.hero-video {
  padding: 0;
  margin: 0;
}

.heromodulecontainer {
  margin: 0 0 1.5%;
  padding: 0;
  /* padding: 0 1% 0 1%; */
}
.actvpmpage .heromodule-caption {
  display: none;
}
.heromodule-caption .sepl-primary-dark {
  background-color: rgb(235 227 227);
}
.hero-one-img {
  width: 100%;
  max-height: 85vh;
  position: relative;
}
.heromodule-caption {
  position: absolute;
  height: 12%;
  top: 55%;
  right: 0%;
  bottom: 0;
  /* transform: translate(-50%, -50%); */
  /* padding: 0 1% 0 1%; */
}
@media (max-width: 570px) {
  .heromodulecontainer {
    position: relative;
    /* padding: 0 1% 0 1%; */
  }
  .heromodule-caption {
    position: absolute;
    top: 25%;
    left: 50%;
    right: 10%;
    bottom: 0;
    padding: 0 1% 0 5%;
    /* transform: translate(-50%, -50%); */
    background-color: rgba(255, 255, 255, 0.65);
  }
}

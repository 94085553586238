@media screen and (max-width: 577px) {
  .image img {
    width: 390px;
    height: 300px;
  }
}
.image {
  text-align: center;
}
.col h1 {
  text-align: center;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: "Playfair Display", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #a2c6ca; */
  background-color: #9cb2c2;
}
body h1 {
  color: #336b87;
}

.sepl-primary-light {
  color: #7d3038;
}

.sepl-primary-dark {
  color: #336b87;
}

.sepl-secondary-light {
  color: #16a4d8;
}
.sepl-secondary-dark {
  color: #8a2808;
}

.seplimg {
  box-shadow: -15px 15px #344c85;
  border-radius: 10px;
}
.seplbutton {
  background-color: #040904;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}
.seplsquare {
  width: 20px;
  height: 20px;
  background-color: #344c85;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.sepl-bg-dark {
  background-color: #e4ddf4;
}

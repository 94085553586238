.backgroundcontrol {
  background-size: cover;
  background-position: bottom center;
  background-color: rgb(52, 51, 51);
}
.contactusbutton {
  background-color: rgb(81, 81, 158);
}
.text {
  color: aliceblue;
}
.buttoncontrol {
  padding: 20px;
}
.container1 {
  margin: 0 0 1.5%;
  padding: 0;
}

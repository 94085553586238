.multimediagallery {
  width: 100%;
  height: 30vh;
}
.multimediagallery-btn {
  width: 100%;
  height: 95%;
}
.multimediagallery-profile-img {
  position: relative;
  width: 100%;
  height: 100%;
}
.modal-dialog{
  max-width: 750px;
} 

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  align-content: center;
  justify-content: center;
}
.carousel-inner {
  height: 540px;  /*Set the desired fixed height for the carousel */
}

.carousel-item img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Maintain image aspect ratio and cover the entire container */
}
.center-modal{
  margin-top: 100px;  /* To keep overflowed image at center of the screen*/
}
/* Media query for smaller screens */
@media (max-width: 800px) {
  .carousel-inner {
    height: 200px; /* Adjust the fixed height for smaller screens */
  }
}
.active-bg{
  background-color: black!important;
}
.fullwidthimage {
  width: 100%;
  height: auto;
}

.fullwidthcol {
  margin-top: -50px;
  margin-left: 4px;
  background-color: rgba(228, 227, 227, 0.952);
  opacity: 0.75;
}
.blockquote-Fullwidth {
  color: rgba(255, 255, 0, 0.922);
  font-style: italic;
  font-size: 36px;
  padding-top: 6px;
  text-align: left;
  margin-bottom: -25px;
}

.blockquote-footer {
  font-style: italic;
  font-size: 22px;
  color: rgba(255, 255, 0, 0.922);
  padding-top: 25px;
  padding-left: -19px;
  text-align: left;
  margin-bottom: -25px;
}

/* .biggerfullwidth {/
  font-size: 20px;
  font-family: Arial, Helvetica, sans-serif;
  font-style: italic;
} */
figure {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 40px;
  margin-inline-end: 40px;
  position: absolute;
}
figcaption {
  display: block;
  /* margin-block-start: 1em;
  margin-block-end: 1em; */
  margin-inline-start: 40px;
  margin-inline-end: 40px;
  position: absolute;
}
@media screen and (max-width: 577px) {
  .fullwidthimage {
    margin-top: 25px;
  }
  .blockquote-Fullwidth {
    font-size: 18px;
  }
  .blockquote-footer {
    /* padding-left: 0px; */
    font-size: 10px;
  }
}

@media screen and (max-width: 767px) {
  .fullwidth .row {
    display: block;
    margin-top: 10px;
  }
  .fullwidthcol {
    margin-top: 10px;
  }
}

.profile {
  float: right;
  width: 20%;
}
@media (max-width: 768px) {
  .profile {
    float: none;
    width: 50%;
    display: block;
    margin: 0 auto;
  }
  .heading {
    text-align: center;
  }
  .from {
    margin-top: 20px;
  }
}

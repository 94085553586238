.five-tile-main-container {
  margin: 0px 10% 0 8%;
  color: white;
}
.five-tile-overlay-container {
  position: relative;
  width: 100%;
  height: 45vh;
}
@media (max-width: 915px) {
  .five-tile-main-container {
    margin: 0 0.5% 0 0.5%;
  }
  .five-tile-overlay-container {
    height: 36vh;
  }
}

.five-tile-overlay-container img {
  position: relative;
  width: 100%;
  height: 100%;
}
.five-tile-overlay-container .overlay {
  position: absolute;
  height: 0;
  overflow: hidden;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.9s ease-in-out;
}
.five-tile-overlay-container .overlay-2 {
  position: absolute;
  height: 0;
  overflow: hidden;
  left: 0;
  right: 0;
  top: 0;
  transition: 0.9s ease-in-out;
}
.five-tile-overlay-container:hover > .overlay {
  background-color: #336b87;
  /* background-image: linear-gradient(
    to right,
    rgb(184, 174, 224) 0%,
    rgb(48, 179, 208) 50%,
    rgb(16, 70, 219) 100%
  ); */
  height: 100%;
}
.five-tile-overlay-container:hover > .overlay-2 {
  background-color: #336b87;

  /* background-image: linear-gradient(
    to right,
    rgb(184, 174, 224) 0%,
    rgb(48, 179, 208) 50%,
    rgb(16, 70, 219) 100%
  ); */
  height: 100%;
}
.fivetile-title {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.fivetile-title-with-img {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.fivetile-title-without-img {
  background-color: #336b87;
  /* background-image: linear-gradient(
    to right,
    rgb(184, 174, 224) 0%,
    rgb(48, 179, 208) 50%,
    rgb(16, 70, 219) 100%
  ); */
}

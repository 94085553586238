.footer {
  width: 100%;
  background-color: #943d24;
  color: white;
  margin: 0;
}
ul {
  list-style: none;
}
.get-in-touch {
  padding: 0;
}
.follow-us {
  padding: 0;
}
.school-logo {
  width: 100%;
  height: 100%;
}
.footer-logo {
  position: relative;
  top: 18%;
  width: 75%;
  height: 50%;
}
.footer-logo1 {
  padding-top: 25%;
  position: unset;
  width: 75%;
  height: 75%;
}
.show-for-mobile-view {
  display: none;
}
@media (max-width: 770px) {
  .footer-logo {
    display: inline-block;
    width: 32%;
    height: 82%;
    left: 32%;
  }
  .footer-logo1 {
    top: -22%;
    right: -10px;
    position: relative;
    width: 87%;
    height: 88%;
    display: inline-block;
  }
  .follow-us {
    display: flex;
  }
  .social-media {
    padding-right: 5px;
  }
  .hide-for-mobile-view {
    display: none;
  } /*
  .show-for-mobile-view {
    display: block;
    color: #ffffff;
  }*/
}
.coloring {
  color: #00f905;
}
ul li.get-in-touch-link a {
  text-decoration: none;
  color: white;
}
ul li a.active {
  text-decoration: none;
  color: rgb(10, 10, 238);
}

.logo {
  width: 6%;
  position: absolute;
  margin-bottom: -1px;
  padding-top: 30px;
}
.seplnavbar {
  background-color: #943d24;
  margin-top: -15px;
}

.navbar-nav .nav-link.active {
  color: #0074ff;
  text-decoration: underline;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-collapse {
  padding-left: 12%;
}
@media (min-width: 992px) {
  #space {
    padding-left: 91px;
  }
}
@media (max-width: 570px) {
  .logo {
    width: 15%;
    left: 82%;
    position: sticky;
    padding-top: 0px;
  }
  .logo1 {
    display: none;
    width: 15%;
  }
  .navbar-collapse {
    padding-left: 0%;
  }
}
.logo1 {
  left: 93%;
  top: -31%;
  width: 5%;
  position: absolute;
  margin-right: 2.1%;
  padding-top: 16px;
}

@media all and (min-width: 992px) {
  .navbar .nav-item .dropdown-menu {
    display: none;
  }

  .navbar .nav-item:hover .dropdown-menu {
    display: block;
  }
  .navbar .nav-item .dropdown-menu {
    margin-top: 0;
  }
}

.filesdiv {
  padding: 20px;
}

.filesdiv h1 {
  font-size: 18px;
  margin-bottom: 10px;
}

.mandatory-table-container,
.table-container {
  overflow-x: auto;
  margin-bottom: 20px;
}

.filesdiv table {
  width: 100%;
  border-collapse: collapse;
  background-color: white;
}

.filesdiv th,
.filesdiv td {
  padding: 12px;
  text-align: left;
  border: 1px solid #ddd;
}

.filesdiv th {
  background-color: #f2f2f2;
  font-size: 14px;
}

.filesdiv td {
  font-size: 14px;
}

.filesdiv table colgroup col:nth-child(1) {
  width: 20%;
}

.filesdiv table colgroup col:nth-child(2),
.filesdiv table colgroup col:nth-child(3) {
  width: 40%;
}

@media screen and (max-width: 768px) {
  .filesdiv th,
  .filesdiv td {
    font-size: 12px;
    padding: 10px;
  }
}

@media screen and (max-width: 480px) {
  .filesdiv h1 {
    font-size: 16px;
  }

  .filesdiv th,
  .filesdiv td {
    font-size: 11px;
    padding: 8px;
  }
}

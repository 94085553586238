@media screen and (min-width: 600px) {
  .subsetion1 {
    margin-top: 70px;

    height: 500px;
    background-color: #afeeee;
    text-align: left;
    opacity: 0.75;
  }
  .sub {
    border-radius: 25px;
    margin-top: 30px;
    margin-left: 70px;
    margin-right: 1px;
    height: 290px;
    max-width: 500px;
    background-color: #afeeee;
    padding-top: -20px;
  }
  .sub1 {
    border-radius: 25px;
    margin-top: 30px;
    margin-left: 70px;
    margin-right: 1px;
    height: 290px;
    max-width: 500px;
    background-color: #d79f12;
    padding-top: -20px;
    color: white;
  }
  .subsetion2 {
    margin-top: 70px;
    margin-left: 4px;
    height: 500px;
    background-color: #d79f12;
    text-align: left;
    opacity: 0.75;
    color: black;
  }

  .Sunsetiontitle {
    color: black;

    text-align: center;
  }
  .Sunsetiontitle1 {
    color: white;
    text-align: center;
  }
  .block-footer1 {
    color: black;
    text-align: center;
  }
  .block-footer2 {
    color: white;

    text-align: center;
  }
  .sunsetionlogo {
    width: 9%;
    position: absolute;
    margin-top: -105px;
  }
  .actlogo {
    width: 4%;
  }
}
@media screen and (max-width: 577px) {
  .subsetion1 {
    margin-top: 70px;
    margin-left: 4px;
    height: 500px;
    background-color: #afeeee;
    text-align: left;
    opacity: 0.75;
  }
  .sub {
    border-radius: 25px;
    margin-top: 30px;
    margin-right: 1px;
    height: 330px;
    max-width: 400px;
    background-color: #afeeee;
    padding-top: -40px;
  }
  .sub1 {
    border-radius: 25px;
    margin-top: 30px;

    margin-right: 1px;
    height: 300px;
    max-width: 400px;
    background-color: #d79f12;
    padding-top: -20px;
    color: white;
  }
  .subsetion2 {
    margin-top: 70px;
    margin-left: 4px;
    height: 500px;
    background-color: #d79f12;
    text-align: centre;
    opacity: 0.75;
  }
  .Sunsetiontitle {
    color: black;

    text-align: center;
  }
  .Sunsetiontitle1 {
    color: white;
    text-align: center;
  }
  .block-footer1 {
    color: black;
    text-align: center;
  }
  .block-footer2 {
    color: white;
    color: white;
    text-align: center;
  }
  .sunsetionlogo {
    width: 25%;
    position: absolute;
    margin-top: -58px;
  }
  .actlogo {
    width: 12%;
  }
}
